import { handleActions } from 'redux-actions'
import { setThemeStylesData } from "../actions/actionTypes"

const initialState = {

}

const handleThemeStylesData = (state, { payload: themeStyles}) => ({
  ...state,
  themeStyles
})

const themeStyles = handleActions({
  [setThemeStylesData]: handleThemeStylesData
}, initialState)

export default themeStyles