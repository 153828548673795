import ducts from '../assets/images/ducts.jpg'
import radiator from '../assets/images/radiator.jpg'
import ventilation from '../assets/images/ventilation.jpg'
import ventilator from '../assets/images/ventilator.jpg'


const landingPage = {
  sections: [
    {
      component: 'Header',
      id: 'NewSectionHeaderID',
      props: {
        children: [
          {
            component: 'Logo',
            props: {
              text: "Martin County Air Conditioning and Heating LL"
            }
          },
          {
            component: 'Menu',
            props: {
              items: [
                {
                  label: (
                    <a href="#home" rel="noopener noreferrer">
                      Home
                    </a>
                  ),
                  key: 'home'
                },
                {
                  label: (
                    <a href="#about-sub-title" rel="noopener noreferrer">
                      About
                    </a>
                  ),
                  key: 'about'
                },
                {
                  label: (
                    <a href="#contact" rel="noopener noreferrer">
                      Contact
                    </a>
                  ),
                  key: 'contact'
                },
                // {
                //   label: 'Navigation Three - Submenu',
                //   key: 'SubMenu',
                //   icon: <SettingOutlined />,
                //   children: [
                //     {
                //       type: 'group',
                //       label: 'Item 1',
                //       children: [
                //         {
                //           label: 'Option 1',
                //           key: 'setting:1',
                //         },
                //         {
                //           label: 'Option 2',
                //           key: 'setting:2',
                //         },
                //       ],
                //     },
                //     {
                //       type: 'group',
                //       label: 'Item 2',
                //       children: [
                //         {
                //           label: 'Option 3',
                //           key: 'setting:3',
                //         },
                //         {
                //           label: 'Option 4',
                //           key: 'setting:4',
                //         },
                //       ],
                //     },
                //   ],
                // },
                // {
                //   label: (
                //     <a href="https://ant.design" target="_blank" rel="noopener noreferrer">
                //       Navigation Four - Link
                //     </a>
                //   ),
                //   key: 'alipay',
                // },
              ]
            }
          }
        ]
      }
    },
    {
      id: 'HeroSection',
      component: 'Section',
      props: {
        flexDirection: 'column',
      children:[{
        id: 'HeroBanner',
        component: 'Banner',
        props: {
          children: [{
            id: 'banner-title',
            component: 'Title',
            props: {
              withComponent: 'h1',
              children: [
                {
                  component: 'RichText',
                  props: {
                      html: 'Your local expert team for</br> HVAC installation, repair, and maintenance.',
                      wrapperElement: 'span'
                  }
                }
              ]
            }
          }]
        }
      },
      {
        id: 'about-sub-title',
        component: 'Container',
        props: {
          justifyContent: 'center',
          children: [{ component: 'Title', props: { margin: '10px 0', children: ['State licensed contractor servicing Florida HVAC needs!'] } }]
        }
      }]
    }
    },
    {
      id : 'about',
      title: 'About',
      component: 'Section',
      props: {
        key: 'about',
        children: [
        {
          component: 'Columns',
          props: {
            width: '25% 25% 25% 25%',
            children: [{
              component: 'Image',
              props: {
                src: ducts
              }
            }, {
              component: 'Image',
              props: {
                src: radiator
              }
            }, {
              component: 'Image',
              props: {
                src: ventilation
              }
            }, {
              component: 'Image',
              props: {
                src: ventilator
              }
            }]
          }
        }],
      }
    },
    {
      id : 'feedback',
      component: 'Section',
      props: {
        key: 'feedback',
        children: [
          {
            id: 'feedback-title',
            component: 'Title',
            props: {
              center: true,
              children: ['Would you recommend us to your friends?']
            }
          },
          {
            component: 'Feedback',
            props: {
              reviewLink: process.env.REACT_APP_REVIEW_LINK
            }
          }
        ]
      }
    },
    {
      id : 'contact',
      component: 'Section',
      props: {
        key: 'contact',
        children: [
          {
            id: 'contact-title',
            component: 'Title',
            props: {
              children: ['Get in Touch Today!']
            }
          },
          {
            id: 'contact-columns',
            component: 'Columns',
            props: {
              adaptive: true,
              width: '50% 5% 45%',
              children: [
                {
                component: 'ContactForm',
              },
              {
                component: ''
              },
              {
                component: 'Container',
                id: 'contact-us-info',
                props: {
                  flexDirection: 'column',
                  children: [
                    // {
                    //   component: 'Title',
                    //   props: {
                    //     withComponent: 'h4',
                    //     children: ['Contact Today']
                    //   }
                    // },
                    {
                      component: 'P',
                      props: {
                        children: [
                          {
                            component: 'Strong',
                            props: {
                              children: ['Phone: ']
                            }
                          },{
                            component: 'Link',
                            props: {
                              to: 'tel:7723497032',
                              children: ['772-349-7032']
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }]
            }
          }
        ]
      },
      title : 'Contact'
    },

    {
      component: 'Footer',
      props: {
        children: [
          {
            component: 'Section',
            props: {
              children: [
                {
                  component: 'Logo'
                },
                {
                  component: 'P',
                  props: {
                    children: ['© Copyright Martin County Air Conditioning and Heating LLC. All Rights Reserved.']
                  }
                }
              ]
            }
          }
        ]
      }
    },

    {
      component: 'Footer'
    }
  ]};
export default landingPage;