import React from "react";
import * as allComponents from "../components";

export const hashCode = (s) => s.split('').reduce((a,b)=>{a=((a<<5)-a)+b.charCodeAt(0);return a&a},0)


export const createE = (def) => {
  const props = {
    ...(def.props || {}),
    ...({id: def.id})
  }
  const components = []
  Object.keys(allComponents).forEach(c => components.push(allComponents[c]))

  const component = components.filter(c => c.type === def.component)[0]
  return component ? React.createElement(
    component,
    props,
    ((props || {}).children || []).map(c => {
      return typeof c === 'string' ? <React.Fragment key={hashCode(c)}>{ c }</React.Fragment> : createE(c)
    })
  ) : <React.Fragment></React.Fragment>
}


const ALLOWED_NESTERS = [
  '&:', '*'
]

const isAllowedNester = (key) => ALLOWED_NESTERS.some(el => key.indexOf(el) === 0)

export const convertComponentStylesToString = (styles) => {
  let style = ''
  for (let key in styles) {
    if (typeof styles[key] === 'string') {
      style = `${style}${key}:${styles[key]};`
    } else if (isAllowedNester(key)) {
      style = `${style}${key}{${convertComponentStylesToString(styles[key])}}`
    }
  }
  return style
}

export const mergeThemeStyles = (defaultTheme, clientTheme) => {
  const theme = {}
  if (typeof defaultTheme === 'string') {
    return clientTheme || defaultTheme
  }
  const keys = Object.keys(defaultTheme)
  const processedKeys = []
  for (let i = 0; i < keys.length; i += 1) {
    processedKeys.push(keys[i])
    theme[keys[i]] = mergeThemeStyles(
      defaultTheme[keys[i]],
      (clientTheme || {})[keys[i]]
    )
  }
  if (clientTheme) {
    const clientKeys = Object.keys(clientTheme)
    for (let i = 0; i < clientKeys.length; i += 1) {
      if (processedKeys.indexOf(clientKeys[i]) === -1) {
        theme[clientKeys[i]] = clientTheme[clientKeys[i]]
      }
    }
  }
  return theme
}

export const getThemeStylesByType = ({id, type, scopes, themeStyles}) => {
  let scopedTheme = { ...themeStyles };

  for (let key in scopes) {
    scopedTheme = scopedTheme[scopes[key]] ? scopedTheme[scopes[key]] : scopedTheme
  }
  scopedTheme = scopedTheme[type] ? scopedTheme[type] : scopedTheme

  if (themeStyles.ElementThemes[type]) {
    scopedTheme = {
      ...themeStyles.ElementThemes[type],
      ...scopedTheme
    }
  }
  if (themeStyles.IDThemes[id]) {
    scopedTheme = {
      ...scopedTheme,
      ...themeStyles.IDThemes[id]
    }
  }

  const theme = convertComponentStylesToString({
    ...scopedTheme
  })
  return theme
}
