import {combineReducers} from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as modal } from 'redux-modal';
import themeStyles from './themeStyles'

const rootReducer = combineReducers({
    form,
    modal,
  themeStyles
});

export default rootReducer;