import React from 'react'
import styled from "styled-components"
import {createComponentType} from "../../helpers/createComponentType";

const CustomSection = ({ children, ...props }) =>
  <StyledSection { ...props }>
    <InnerContainer>{ children }</InnerContainer>
  </StyledSection>

const StyledSection = styled.section`
  box-sizing: border-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  min-height: ${props => props.minHeight || '0'};
`
const InnerContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
`

export const Section = createComponentType({ type: 'Section', tagname: CustomSection})
