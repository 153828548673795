import styled from 'styled-components'
import {createComponentType} from "../../helpers/createComponentType";

const BannerWrapper = styled.div`
    width: 100%;
    height: 500px;
    background-size: cover;
    color: white;
    font-size: 40px;
    font-weight:bold;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
`

export const Banner = createComponentType({ type: 'Banner', tagname: BannerWrapper});