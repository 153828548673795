import React from 'react'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import { Form, Input, Button } from 'antd';
import {createComponentType} from "../../helpers/createComponentType";
import ReCAPTCHA from 'react-google-recaptcha';
const { TextArea } = Input;
const FormItem = Form.Item;

import { API } from 'aws-amplify';
import { createContact } from '../../graphql/mutations';


const SuccessMessage = () =>
  <StyledSuccessMessage> Thank you! Someone will be in touch soon!</StyledSuccessMessage>

const Captcha = (props) => (

    <div>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
        onChange={props.input.onChange}
      />
      </div>
  );

const makeField = Component => ({ input, meta, children, hasFeedback, label, ...rest }) => {
    const hasError = meta.touched && meta.invalid;
    return (
        <FormItem
            label={label}
            validateStatus={hasError ? "error" : "success"}
            hasFeedback={hasFeedback && hasError}
            help={hasError && meta.error}
        >
            <Component {...input} {...rest} children={children} />
        </FormItem>
    );
};
const AInput = makeField(Input);

const createNewContact = async (values) => {
  const {name, email, phone, subject = "contact", message="inquiry"} = values; 
  const result = await API.graphql({
    query: createContact,
    variables: {
      input: {
        name,
        email,
        phone,
        subject,
        message
      },
    },
  })
  return result
}

const defaultSubmitHandler = (values) => createNewContact(values).then((result) => console.log(result))

let StyledContactForm = props => {
    const { invalid, submitting, pristine, handleSubmit, submitSucceeded } = props;

    return submitSucceeded ?  <SuccessMessage /> : <form onSubmit={handleSubmit}>
            <div>
                <label htmlFor="name"><h3 style={{'textAlign': 'left'}}>Name</h3></label>
                <Field name="name" component={AInput} size="large" type="text" placeholder={'Name'} />
            </div>
            <div>
                <label htmlFor="email"><h3 style={{'textAlign': 'left'}}>Email</h3></label>
                <Field name="email" component={AInput} size="large" type="email" placeholder={'Email'} />
            </div>
            <div>
                <label htmlFor="phone"><h3 style={{'textAlign': 'left'}}>Phone</h3></label>
                <Field name="phone" component={AInput} size="large" type="tel" placeholder={'Phone'} />
            </div>
            <Field name='captcharesponse' component={Captcha}/>
            <StyledButton htmlType="submit" disabled={invalid || pristine || submitting}>Submit</StyledButton>
        </form>
    
}

const StyledSuccessMessage = styled.p`
  text-align:center;
  font-size: 30px;
`

const StyledButton = styled(Button)`
    margin-top: 10px;
    margin-bottom: 10px;
`

const validate = (values) => {
    const { recaptcha, captcharesponse } = values;
    const errors = {};

    if (!captcharesponse) {
        errors.captcharesponse = 'reCAPTCHA required.';
      }

    if (!values.name) {
        errors.name = 'Required'
      }
    if (!values.phone) {
      errors.phone = 'Required'
    }
    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
}

export const ContactForm = createComponentType({ type: 'ContactForm', tagname: reduxForm({
    // a unique name for the form
    validate: validate,
    onSubmit: defaultSubmitHandler,
    form: 'contact'
  })(StyledContactForm)})