import React from 'react'
import styled from 'styled-components';
import {createComponentType} from "../../helpers/createComponentType";
import logo from '../../assets/images/martin-county-hvac-logo-horizontal.svg'
// Rework so it doesnt rely on props.style
const StyledLogo = ({src, ...props}) => {
    return (
        <StyledLogoContainer />

    )
}
//             {/* {props.theme && props.theme.logo ? <StyledImage style={props.style} />: <StyledText>{props.text}</StyledText> }
// </LogoContainer> */}

const StyledLogoContainer = styled.div`
    float: left;
    width: 200px;
    height: 66px;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 16px 24px 16px 0;
    background-image: url(${logo})
`

const StyledText = styled.h1`
    line-height: 1em;
    font-size: 2em;
`

const StyledImage = styled.img.attrs({
  src: props => props.theme.logo
})`
      float:left;
      text-align: ${props => props.position ? props.position : 'left'};
      margin: ${props => props.position === 'center' ? 'auto' : '0'};
      width: ${props => !!props.width ? props.width : '300px;'};
      height: 100px;
`
const LogoContainer = styled.div`
    float:left;
    width: 300px;
`

export const Logo = createComponentType({ type: 'Logo', tagname: StyledLogo})
