import React from 'react';
import Main from './components/Main';
import 'antd/dist/antd.min.css';
import { Provider } from 'react-redux';
// import { Router } from 'react-router'
import { Routes, Route, BrowserRouter as Router} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import config from '../src/aws-exports'
Amplify.configure(config)

const App = ({store }) =>
    <Provider store={ store }>
        <Router>
            <Routes>
                <Route path='/' element={<Main/>} />
            </Routes>
        </Router>
    </Provider>

export default App;


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//           <h1>Martin County Air Conditioning and Heating</h1>
//           <p>Website coming soon!</p>
//           <a href="tel:17723497032">Call 1-772-349-7032 for an estimate.</a>
//       </header>
//     </div>
//   );
// }

// export default App;
