import React from 'react'
import styled, { css } from "styled-components"
import {createComponentType} from "../../helpers/createComponentType";
import { default as Media } from"../common/Media";

const StyledColumns = ({ children, width = '', verticalAlign, adaptive, padding, ...props}) => {
  const sizes = width.split(' ')
  return (
    <Table { ...props }>
      {
        React.Children.toArray(children)
          .map((item, index) =>
            <Column data-column key={index} width={sizes[index] || 'auto'} adaptive={adaptive} verticalAlign={verticalAlign} padding={padding}>
              {item}
            </Column>
          )
      }
    </Table>
  )
}

const Table = styled.div`
  display: table;
  width: 100%;
  height: 100%;
`

const Column = styled.div`
  display: table-cell;
  vertical-align: top;
  padding: ${props => props.padding || 0};
  height:  ${props => props.height || '100%'};
  width: ${props => props.width};
  ${props => props.verticalAlign && css`
    vertical-align: ${props.verticalAlign};
  `}
  ${props => props.adaptive && css`
    ${Media.mobile`
      display: block;
      width: auto;
    `}
  `}
`

export const Columns = createComponentType({ type: 'Columns', tagname: StyledColumns})
