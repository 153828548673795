import React from 'react';
import styled, {css} from "styled-components";
import {compose} from "redux";
import withType from '../components/common/hoc/withType';
import withThemeStyles from '../components/common/hoc/withThemeStyles';
import { injectProps } from './utils'

export const createComponentType = ({type, tagname, attrs = {}, propsToExclude=[]}) => {

  const StyledTagRoot = (typeof tagname === 'string' ? styled[tagname] : styled(tagname)).attrs(attrs)``
  const StyledTag = styled(({ themeStyle, ...rest }) => <StyledTagRoot { ...rest } />)`
    ${props => props.themeStyle && css`${props.themeStyle}`}
  `
  ;

  const Component = ({ children, scopes = [], type, withComponent, ...props }) => {
    const FinalStyledTag = withComponent ? StyledTag.withComponent(withComponent) : StyledTag
    return <FinalStyledTag {...Object.fromEntries(Object.entries(props).filter(([key]) => !propsToExclude.includes(key)))}>
      {typeof children === 'string' ? children : injectProps(children, {scopes: [...scopes, type]})}
    </FinalStyledTag>
  }

  return compose(
    withType({ type }),
    withThemeStyles
  )(Component)
}