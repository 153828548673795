import React from 'react'
import App from './App'
import configureStore from './store/configureStore'
// import createHistory from 'history/createBrowserHistory'
import { mergeThemeStyles } from './helpers/helpers'
import defaultTheme from './mocks/DefaultTheme'
import clientTheme from './mocks/ClientTheme'

// const history = createHistory()

const store = configureStore({
  themeStyles: mergeThemeStyles(defaultTheme, clientTheme)
})

export default function ConfiguredApp() {
    return (
        <App store={ store } />
    )
}